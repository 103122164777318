<template>
<div id="CourseDetails" v-if="CourseData">

<div style="padding: 2vw;" class="bg-accent-card pa-5 secondary elevation-5">
     <p class="mt-5 display-1">Course Details</p>
</div>

<v-container fluid class="darken-1" style="color: #000;margin-top: -200px">
<v-card class="pa-2">

<v-card-title>
    <div class="headline mt-5 mb-0 px-5" style="border-left: 10px solid #000;color: #000">{{CourseData.title}}</div>

</v-card-title>

<v-row style="padding: 0 2vw">

    <v-col cols="12" sm="12" md="5" class="d-flex align-center justify-center">
        <v-img :src="CourseData.imageUrl" height="300px" alt="Event Image" 
               class="elevation-10 white animated fadeIn slow">
            <template v-slot:placeholder>
                <v-row class="fill-height ma-0" align="center" justify="center">
                <v-progress-circular :size="80" :width="15" color="purple" indeterminate ></v-progress-circular>
                </v-row>
            </template>
        </v-img>
    </v-col>

    <v-col cols="12" sm="12" md="7">

        <div style="padding: 2vw">

            <v-row>
                <v-col cols="12" sm="12" md="6">
                    <p class="title mb-1">Eligibility :</p>
                    <p class="subtitle-1 mb-1">{{CourseData.eligibility}}</p>
                </v-col>
                <v-col cols="12" sm="12" md="6" v-if="CourseData.subjects.length > 1">
                    <div>
                        <p class="title mb-1">Subjects :</p>
                        <p class="subtitle-1 mb-1">{{SubjectsString}}</p>
                    </div>
                </v-col>
                <v-col cols="12" sm="12" md="12">
                    <p class="title mb-1">Time :</p>
                    <p class="subtitle-1 mb-1">{{CourseData.time}}</p>
                </v-col>
                <v-col cols="12" sm="12" md="12">
                    <p class="title mb-1">Support : 24/7</p>
                </v-col>
            </v-row>
  
        </div>

    </v-col>

    <v-col cols="12" sm="12" md="12">
        <div style="padding: 2vw 1vw;">
            <p class="title">Description :</p>
            <p class="subtitle-1">{{CourseData.description}}</p>
        </div>
    </v-col>

    <v-col class="d-flex align-center justify-center" v-if="StudentData.length != 0"><hr width="95%"></v-col>
    
</v-row>

<!-- students on course -->
<v-container v-if="StudentData.length != 0" class="d-flex align-center justify-center" style="flex-direction: column;">

<p class="headline text-center">Students on this Course</p>
<hr width="20%">

<v-container class="my-5 py-5">
    {{StudentData}}
</v-container>
</v-container>

</v-card>
</v-container>

<v-btn class="secondary" @click="$router.go(-1)">go back</v-btn>
<v-btn color="primary " @click="$router.push('/editcourse/' + CourseSlug)">Edit</v-btn>
<v-btn color="red darken-1 " :disabled="disbtn" @click="deleteDialog = true">Delete</v-btn>

<!-- delete modal -->
<v-dialog v-model="deleteDialog" persistent max-width="290" transition="slide-y-transition">
<v-card>
<v-card-title class="headline">Confirm Delete?</v-card-title>
<v-card-text>
    This action can't be undone!
</v-card-text>
<v-card-actions>
    <v-spacer></v-spacer>
    <v-btn color="primary" text @click="deleteDialog = false">Cancel</v-btn>
    <v-btn color="primary" text @click="DeleteCourse()">Delete</v-btn>
</v-card-actions>
</v-card>
</v-dialog>
<!-- delete dialog ends -->

<!-- show overlay when add item -->
<v-fade-transition>
<v-overlay absolute :value="disbtn">
<v-progress-circular color="yellow" indeterminate width="15" size="100"></v-progress-circular>
</v-overlay>
</v-fade-transition>

</div>
</template>

<script>
import firebase from 'firebase/app';
import "firebase/firestore";
import "firebase/storage";


export default {

    name: 'CourseDetails',

    computed: {

        CourseSlug(){         
          return this.$route.params.courseslug != null ? this.$route.params.courseslug : null;
        },

        CourseData(){
            if(this.CourseSlug != null) {
                let data =  this.$store.getters.GetCourseWithSlug(this.CourseSlug);
                return data.length != 0 ? data[0] : this.$router.push("/courses");
            }else{ return this.$router.push("/courses"); }             
        },

        SubjectsString(){
            if(this.CourseData.subjects.length > 1){
                let index = this.CourseData.subjects.indexOf('All');
                if(index != -1){ this.CourseData.subjects.splice(index, 1);}
                return this.CourseData.subjects.toString()
            }
        },

        StudentData(){
            if(this.CourseData != null){
                return this.$store.getters.StudentsOnCourse(this.CourseData.title)
            }
        }
        
    },

    data(){return{

        deleteDialog: false,disbtn: false,
    }},

    methods: {

        async DeleteCourse(){
            this.deleteDialog = false;
            this.disbtn = true;
             // get image url
            let url
            if(this.CourseData.imageUrl){
                url = this.CourseData.imageUrl.toString();
            }
            // get document where student id equals selected one
            await firebase.firestore().collection("courses")
            .where("courseslug", "==", this.CourseSlug).get().then(
            (doc) => { if(doc.docs[0].id){
                // update all datas(sorry for cramping it up)
                firebase.firestore().collection("courses").doc(doc.docs[0].id).delete()
                .then(
                (result) => { 
                    this.disbtn = false;
                    if(url){
                        firebase.storage().refFromURL(url).delete().then((succ) => {this.$router.push("/courses");});
                    }else{this.$router.push("/courses");}
                });}       
            }, (err) => { console.log(err);  }); 
        },
    }
}
</script>
